
import barba from '@barba/core';
import KeenSlider from 'keen-slider';


export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.loopSlider()
        })
        barba.hooks.after((data) => {
            this.loopSlider()
        });
    }
    
    loopSlider(){
        const indexLoop = document.querySelector("#my-keen-slider");
        if(indexLoop) {
            const animation = { duration: 14000, easing: (t) => t }

            new KeenSlider('#my-keen-slider', {
                loop: true,
                //mode:'free',
                renderMode: "performance",
                drag: false,
                slides: {
                    perView: 1.5,
                },
                breakpoints: {
                    '(min-width: 576px)': {
                        slides: {
                            perView: 2.5,
                        },
                    },
                    '(min-width: 768px)': {
                        slides: {
                            perView: 3.5,
                        },
                    },
                    '(min-width: 1499px)': {
                        slides: {
                            perView: 4.5,
                        },
                    },
                },
                created(s) {
                    s.moveToIdx(2, true, animation)
                },
                updated(s) {
                    s.moveToIdx(s.track.details.abs + 2, true, animation)
                },
                animationEnded(s) {
                    s.moveToIdx(s.track.details.abs + 2, true, animation)
                },
            });
        }
    }
}
