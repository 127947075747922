
import barba from '@barba/core';
import anime from 'animejs';

export default class{
	constructor(){
        barba.hooks.afterOnce((data) => {
            this.init() //thisは全体を指す（一番親）。その中のinitを実行する。
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    init (){
        const links = document.querySelectorAll('.p-index__thought__link--item')
        const wrapper = document.querySelector('.p-index__thought__link')

        links.forEach((element,index) => {
            const name = element.getAttribute('data-name')
            element.addEventListener("mouseenter",() => {
                wrapper.classList.add('-' + name )
            }) //マウスが乗ったときに発火

            element.addEventListener("mouseleave",() => {
                wrapper.classList.remove('-' + name )
            }) //マウスが離れたときに発火
        })
        // links.addEventListener("mouseenter",()=>{
        //     console.log("mouseenter")
        // })

    }
}