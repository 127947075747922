
import barba from '@barba/core';
import Swiper from 'swiper';

export default class{

	constructor(){

        barba.hooks.beforeOnce((data) => {
            this.heroSlider(true)
            this.conceptSlider(true)
            this.courseSlider()
        })
        barba.hooks.after((data) => {
            this.heroSlider(false)
            this.conceptSlider(false)
            this.courseSlider()
        });
        
    }
    
    heroSlider(isOnce){
        let delay = (isOnce) ? 5000:0
        setTimeout(function(){
            const heroSlider =  new Swiper ('.l-hero__slider',{
                effect :'fade',
                autoplay: true,
                speed: 2500,
            })
        },delay)
    }

    conceptSlider(isOnce){
        let delay = (isOnce) ? 5000:0
        setTimeout(function(){
            const conceptSlider =  new Swiper ('.p-index__medical__slider',{
                effect :'fade',
                autoplay: true,
                speed: 2500,
            })
        },delay)
    }

    courseSlider(){
        const courseSlider =  new Swiper ('.js-lower__slider',{
            loop: true,
            loopedSlides: 2,
            slidesPerView: "auto",
            allowTouchMove: false,
            speed: 8000,
            autoplay: {
                delay: 0,
                disableOnInteraction: false,
            },
        })
    }
}
