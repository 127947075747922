
import barba from '@barba/core';
import MicroModal from 'micromodal';


export default class{

	constructor(){

        this.triggerClass = "js-modal-video__open"
        this.videoWrapperId = "c-video__wrapper"
        this.videoIframeId= "c-video__iframe"

        this.Player = {}
        this.scriptTag = document.createElement('script')
        this.scriptTag.src = "https://www.youtube.com/player_api"
        const firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(this.scriptTag, firstScriptTag)

        barba.hooks.afterOnce((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    init (){
        const triggers = document.querySelectorAll('.' + this.triggerClass)
        if(triggers.length > 0){
            triggers.forEach(t => {
               this._onClick(t)
            })
        }
        
    }
    _onClick(el){
        el.addEventListener('click',(e) => {
            e.preventDefault()
            const id = el.getAttribute("data-id")

            MicroModal.show('modal-video',{
                onShow: modal => {
                    this.onYouTubeIframeAPIReady(id)
                }, 
                onClose: modal => {
                    const wrapper = document.querySelector('#' + this.videoWrapperId)
                    // this.Player.pauseVideo();
                    wrapper.innerHTML = '<div id="' + this.videoIframeId + '"></div>'
                }, 
                disableFocus: true,
                awaitOpenAnimation: true,
                awaitCloseAnimation: true
            });
        })
    }
    onYouTubeIframeAPIReady(id) {
        const el = document.querySelector('#' + this.videoIframeId)
        this.Player = new YT.Player( el, {
            videoId: id,
            playerVars: {
                rel: 0,
                autoplay: 0,
                controls:0,
                loop:1,
                playsinline: 1
            },
            events: {
                'onReady': this.onPlayerReady
            }
        })
 
    }
    onPlayerReady(e) {
        // this.ytData.forEach(d => {
        //     if(e.target.getIframe().id == d['div_id']) {
                
        //     }
        // })
        e.target.setVolume(70)
        e.target.playVideo()
    }
    // onPlayerStateChange(event) {
    //     const ytStatus = event.data;
        
    //     // 再生中のとき
    //     if (ytStatus == YT.PlayerState.PLAYING) {
    //         console.log('再生中');
    //     }
    //     // 停止中のとき
    //     if (ytStatus == YT.PlayerState.PAUSED) {
    //         console.log('停止中');
    //     }
    //     // バッファリング中のとき
    //     if (ytStatus == YT.PlayerState.BUFFERING) {
    //         console.log('バッファリング中');
    //     }
    //     if (ytStatus == YT.PlayerState.CUED) {
    //         console.log('頭出し済み');
    //     }
    //  }
}