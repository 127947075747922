

import anime from 'animejs';

export default class{
    /**
     * loading要素の初期設定等を行うコンストラクタ
     * DOM生成時に即時実行
     */
    constructor() {
        this.mask = document.querySelector('#js-loading__mask')
        this.first = "#js-loading__mask .js-loading__first"
        this.second = "#js-loading__mask .js-loading__second"
        this.logoAnimation_elm = document.querySelector('#logo-animation')
        if(this.mask){
            this.mask.style.display = "block"
        }

        if(process.env.MIX_DISP_LOADING == "false" && process.env.NODE_ENV == "development"){
            if(this.mask){
                this.mask.style.display = "none" 
            }
        }
    }
    /**
     * 初期表示前に1度だけ実行
     * @param {*} data 
     */
    beforeOnce(data){
        const self = this;
        return new Promise(resolve => {

            let timeline = anime.timeline();
            
            timeline
            .add({
                targets: '#catch-animation svg path',
                opacity:[0,1],
                translateY:["-20px",0],
                duration: 2000,
                easing:"easeOutQuart",
                delay: (el,i)=>{
                    return i * 100
                }
            })
            .add({
                targets: '#logo-animation',
                opacity:{
                    value:[0, 1],
                    duration:1500,
                },
                scale:{
                    value: [1.1, 1],
                    duration:1500,
                },
                perspective: {
                    value:["1000px", 0],
                    duration:1500,
                },
                easing: 'easeOutExpo',
                duration : 2400,
                complete: () =>{
                    const logo = document.querySelector('#logo-animation')
                    if(logo){
                        logo.style.transformOrigin = "bottom center"
                    }
                    resolve();
                }
            // 初期読み込み時の待ち時間
            },2000)
        })
    }
    /**
     * 初期表示時に1度だけ実行
     * @param {*} data 
     */
    once(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 初期表示完了後に1度だけ実行
     * @param {*} data 
     */
    afterOnce(data){

        return new Promise(resolve => {


            anime.set( '#l-hero__catch .l-hero__catch-inner svg path', {
                opacity:0,
            });
            const timelime = anime.timeline()
            timelime
            .add({
                targets: this.first ,
                opacity:[1,0],
                easing: 'easeOutCubic',
                duration : 2000 //ローディングが消えるまでにかかる時間
            })
            .add({
                targets: '#catch-animation',
                opacity: [1, 0],
                easing: 'easeOutExpo',
                duration : 1000,
            },"-=2000")
            .add({
                targets: '#logo-animation',
                opacity:[1,0],
                // scale:{
                //     value: [1,0.8],
                //     duration:2000,
                // },
                // perspective: {
                //     value:[0,"1000px"],
                //     duration:1300,
                // },
                easing: 'easeOutExpo',
                duration : 1000,
                complete: () =>{
                    this.mask = document.querySelector('#js-loading__mask')
                    if(this.mask){
                        this.mask.style.display = "none"
                    }
                    resolve(); 
                    
                    const delay = 1000 //KV文字の開始
                    anime({
                        targets: '#l-hero__catch .l-hero__catch-inner svg path',
                        opacity:[0,1],
                        translateY:["-20px",0],
                        duration: 2000,
                        easing:"easeOutQuart",
                        delay: (el,i)=>{
                            return i * 100 + delay
                        }
                    })
                }
            },"-=2000")//ローディング文字消えるまでにかかる時間
        });
    }
    /**
     * 次ページ読み込み前に実行
     * @param {*} data 
     */
    beforeLeave(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ読み込み時に実行
     * @param {*} data 
     */
    leave(data){

        return new Promise(resolve => {
            this.mask = document.querySelector('#js-loading__mask')
            if(this.mask){
                this.mask.style.display = "block"
            }
            
            data.next.container.style.opacity = 0;
            const timelime = anime.timeline()
            timelime
            
            .add({
                targets: this.first,
                opacity : [0,1],
                easing: 'easeInExpo',
                duration : 300,
                complete : () => {
                    resolve();
                }
            })

        });
    }
    /**
     * 次ページ読み込み完了後に実行
     * @param {*} data 
     */
    afterLeave(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ表示前に実行
     * @param {*} data 
     */
    beforeEnter(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ表示時に実行
     * @param {*} data 
     */
    enter(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ表示完了後に実行
     * @param {*} data 
     */
    afterEnter(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * すべて完了後に実行
     * @param {*} data 
     */
    after(data){

        return new Promise(resolve => {

            const timelime = anime.timeline()
            timelime
            .add({
                targets: this.first ,
                opacity:[1,0],
                easing: 'easeOutCubic',
                duration : 600,
                begin: () => {
                    this.initialScroll ()
                    data.next.container.style.opacity = 1;
                }
            },200)
            .add({
                targets: this.second,
                opacity:[1,0],
                easing: 'easeInCubic',
                duration : 600,
                complete : () => {
                    this.mask = document.querySelector('#js-loading__mask')
                    if(this.mask){
                        this.mask.style.display = "none"
                    }
                    resolve();
                }
            },'-=400')

            
        });
    }
    initialScroll (){
        if( location.hash == "" ){
            window.scrollTo(0,0);
        }else{
            let digit = location.hash.replace(/^#/,"");
            let anchor = document.getElementById( digit );
            if ( !anchor ) return;
            
            if(anchor){
                const rect = anchor.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                let top = rect.top + scrollTop;
                const header = document.querySelector('[data-header]');
                if(header){
                        top = top - header.clientHeight;
                }
                window.scrollTo(0,top);
            }
            
        }
    }
}